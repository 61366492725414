import {FACEBOOK_URL} from '@cohort/shared/apps/facebook/common';
import type {FacebookLikePostVerificationAttemptData} from '@cohort/shared/apps/facebook/triggers/likePost';
import {Trans} from 'react-i18next';

const FacebookLikePostTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: FacebookLikePostVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  const {username} = data;
  const linkClassName = 'font-semibold text-gray-900';
  const linkRel = 'noopener noreferrer nofollow';

  if (!completionSuccess) {
    return (
      <Trans
        i18nKey="triggers.like-post.stepCompletionTitleComponent.error"
        ns="app-facebook"
        values={{username}}
        components={{
          userLink: (
            <a
              className={linkClassName}
              target="_blank"
              rel={linkRel}
              href={data.userLink ?? FACEBOOK_URL}
            />
          ),
        }}
      />
    );
  }

  return (
    <Trans
      i18nKey="triggers.like-post.stepCompletionTitleComponent.success"
      ns="app-facebook"
      values={{username}}
      components={{
        userLink: (
          <a
            className={linkClassName}
            target="_blank"
            rel={linkRel}
            href={data.userLink ?? FACEBOOK_URL}
          />
        ),
      }}
    />
  );
};

export default FacebookLikePostTriggerIntegrationStepCompletionTitleComponent;
