import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {assertConnectionId} from '@cohort/merchants/apps';
import {useFacebookPosts} from '@cohort/merchants/apps/facebook/actions';
import FacebookPostSelector from '@cohort/merchants/apps/facebook/triggers/FacebookPostSelector';
import ErrorState from '@cohort/merchants/components/ErrorState';
import Loader from '@cohort/merchants/components/Loader';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useEffect, useMemo} from 'react';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const FacebookLikePostTriggerIntegrationConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = ({connectionId, formReturn}) => {
  assertConnectionId(connectionId);

  const merchant = useCurrentMerchant();
  const {setValue, control} = formReturn;
  const {field: postIdField} = useController({name: 'triggerIntegrationConfig.postId', control});
  const {t} = useTranslation('app-facebook', {
    keyPrefix: 'triggers.comment-post.configComponent',
  });

  const {
    data: postsResponse,
    isLoading: isPostsLoading,
    isFetched: isPostsFetched,
  } = useFacebookPosts(merchant.id, connectionId);

  const posts = useMemo(() => postsResponse?.output.posts ?? [], [postsResponse]);

  // Set the value of the post to match the postId
  useEffect(() => {
    const post = posts.find(post => post.id === postIdField.value);
    if (post === undefined) {
      return;
    }
    setValue('triggerIntegrationConfig.post', post);
  }, [postIdField, posts, setValue]);

  const formsSelectionContent = match({
    posts,
    isPostsLoading,
    isPostsFetched,
    formIdFieldValue: postIdField.value,
  })
    .with({isPostsLoading: true}, () => <Loader size={30} color="gray" />)
    .with({posts: []}, () => <p>{t('noPostsMessage')}</p>)
    .with({isPostsFetched: true}, () => {
      const selectedPost = posts.find(post => post.id === postIdField.value);

      return (
        <FacebookPostSelector
          showDefaultSelectButton={postIdField.value === undefined}
          description={t('descriptionPostSelect')}
          formReturn={formReturn}
          title={t('titlePostSelect')}
          selectedPost={selectedPost}
          posts={posts}
        />
      );
    })
    .otherwise(() => <ErrorState />);

  return formsSelectionContent;
};

export default FacebookLikePostTriggerIntegrationConfigComponent;
