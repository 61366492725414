import FacebookPostCard from '@cohort/merchants/apps/facebook/triggers/FacebookPostCard';
import SelectPostSheet from '@cohort/merchants/apps/facebook/triggers/SelectPostSheet';
import Button from '@cohort/merchants/components/buttons/Button';
import HighlightText from '@cohort/merchants/components/HighlightText';
import type {StepTriggerFormType} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/challenge/utils';
import type {FacebookPost} from '@cohort/shared/apps/facebook/common';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {ArrowsCounterClockwise, ImagesSquare} from '@phosphor-icons/react';
import {Fragment, useState} from 'react';
import type {UseFormReturn} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type FacebookPostSelectorProps = {
  showDefaultSelectButton: boolean;
  description: string;
  formReturn: UseFormReturn<StepTriggerFormType>;
  title: string;
  posts: FacebookPost[];
  selectedPost?: FacebookPost;
};

const FacebookPostSelector: React.FC<FacebookPostSelectorProps> = ({
  showDefaultSelectButton,
  description,
  formReturn,
  selectedPost,
  title,
  posts,
}) => {
  const [selectPostSheetOpen, setSelectPostSheetOpen] = useState(false);
  const {t} = useTranslation('app-facebook', {
    keyPrefix: 'components.facebookPostSelector',
  });

  return (
    <Fragment>
      <div>
        <p className="pb-1 text-sm font-medium">{title}</p>
        <p className="pb-2 text-sm">{description}</p>
        {showDefaultSelectButton ? (
          <Button variant="ghost" onClick={() => setSelectPostSheetOpen(true)}>
            <ImagesSquare className="mr-2 h-5 w-5" />
            {t('buttonSelectPost')}
          </Button>
        ) : (
          <Fragment>
            <div
              className={cn(
                'flex gap-5',
                selectedPost !== undefined ? 'w-fit flex-col' : 'items-center'
              )}
            >
              <div
                className={cn(
                  'rounded-lg border border-slate-200 shadow-sm',
                  selectedPost !== undefined && 'w-[592px]'
                )}
              >
                <FacebookPostCard post={selectedPost} />
              </div>
              <div>
                <Button variant="secondary" onClick={() => setSelectPostSheetOpen(true)}>
                  <ArrowsCounterClockwise className="mr-2 h-5 w-5" />
                  {t('buttonChangePost')}
                </Button>
              </div>
            </div>

            {selectedPost === undefined && (
              <div className="mt-4">
                <HighlightText type="warning" text={t('postNotFound')} />
              </div>
            )}
          </Fragment>
        )}
      </div>
      {selectPostSheetOpen && (
        <SelectPostSheet
          formReturn={formReturn}
          posts={posts}
          onClose={() => setSelectPostSheetOpen(false)}
        />
      )}
    </Fragment>
  );
};

export default FacebookPostSelector;
