import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {assertConnectionId} from '@cohort/merchants/apps';
import {useDiscordRoles} from '@cohort/merchants/apps/discord/api';
import ErrorState from '@cohort/merchants/components/ErrorState';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import type {SelectOption} from '@cohort/merchants/components/form/select/SelectPicker';
import Loader from '@cohort/merchants/components/Loader';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const DiscordHasRoleTriggerIntegrationConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = ({formReturn: {control, register, setValue}, connectionId}) => {
  assertConnectionId(connectionId);

  const merchant = useCurrentMerchant();
  const {field: roleIdField} = useController({name: 'triggerIntegrationConfig.roleId', control});
  const {t} = useTranslation('app-discord', {
    keyPrefix: 'triggerIntegrations.has-role.configComponent',
  });

  const {
    data: discordRolesRes,
    isLoading: isLoading,
    isFetched: isFetched,
  } = useDiscordRoles(merchant.id, connectionId);

  const discordRoles = discordRolesRes?.output.roles ?? [];

  const handleRoleChange = (roleName: string | undefined): void => {
    if (roleName === undefined) {
      return;
    }

    setValue('triggerIntegrationConfig.roleName', roleName);
  };

  const formsSelectionContent = match({
    discordRoles,
    isLoading,
    isFetched,
    roleIdFieldValue: roleIdField.value,
  })
    .with({isLoading: true}, () => <Loader size={30} color="gray" />)
    .with({discordRoles: []}, () => <p>{t('roleSelect.noRolesMessage')}</p>)
    .with({isFetched: true}, () => (
      <div>
        <SelectInput
          labelPosition="top"
          label={t('roleSelect.labelRoleSelect')}
          options={discordRoles.map(
            role =>
              ({
                value: role.id,
                label: role.name,
              }) satisfies SelectOption
          )}
          name="triggerIntegrationConfig.roleId"
          register={register}
          control={control}
          onChange={option => handleRoleChange(option?.label as string | undefined)}
        />
      </div>
    ))
    .otherwise(() => <ErrorState />);

  return formsSelectionContent;
};

export default DiscordHasRoleTriggerIntegrationConfigComponent;
