import {FACEBOOK_URL} from '@cohort/shared/apps/facebook/common';
import type {FacebookCommentPostVerificationAttemptData} from '@cohort/shared/apps/facebook/triggers/commentPost';
import {Trans} from 'react-i18next';

const FacebookCommentPostTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: FacebookCommentPostVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  const {username, comment} = data;
  const linkClassName = 'font-semibold text-gray-900';
  const linkRel = 'noopener noreferrer nofollow';

  if (!completionSuccess || comment === null) {
    return (
      <Trans
        i18nKey="triggers.comment-post.stepCompletionTitleComponent.error"
        ns="app-facebook"
        values={{username}}
        components={{
          userLink: (
            <a
              className={linkClassName}
              target="_blank"
              rel={linkRel}
              href={data.userLink ?? FACEBOOK_URL}
            />
          ),
        }}
      />
    );
  }

  return (
    <Trans
      i18nKey="triggers.comment-post.stepCompletionTitleComponent.success"
      ns="app-facebook"
      values={{username}}
      components={{
        userLink: (
          <a
            className={linkClassName}
            target="_blank"
            rel={linkRel}
            href={data.userLink ?? FACEBOOK_URL}
          />
        ),
        commentLink: (
          <a className={linkClassName} target="_blank" rel={linkRel} href={comment.permalinkUrl} />
        ),
      }}
    />
  );
};

export default FacebookCommentPostTriggerIntegrationStepCompletionTitleComponent;
