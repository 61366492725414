import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {assertConnectionId} from '@cohort/merchants/apps';
import {useTypeformForm, useTypeformForms} from '@cohort/merchants/apps/typeform/api';
import ErrorState from '@cohort/merchants/components/ErrorState';
import Input from '@cohort/merchants/components/form/input/Input';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import type {SelectOption} from '@cohort/merchants/components/form/select/SelectPicker';
import HighlightText from '@cohort/merchants/components/HighlightText';
import Loader from '@cohort/merchants/components/Loader';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {TypeformTakeQuizTriggerIntegrationSpec} from '@cohort/shared/apps/typeform/triggers/takeQuiz';
import {useEffect} from 'react';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

// @Devs - Using the same config component for Fill form and Quiz trigger integrations.
const TypeformTriggerIntegrationConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = ({connectionId, formReturn: {control, register, setError, clearErrors}}) => {
  assertConnectionId(connectionId);

  const merchant = useCurrentMerchant();
  const {field: formIdField} = useController({name: 'triggerIntegrationConfig.formId', control});
  const {field: triggerIntegrationId} = useController({name: 'triggerIntegrationId', control});
  const {t} = useTranslation('app-typeform', {
    keyPrefix: 'triggerIntegrations.configComponent',
  });

  const {
    data: typeformFormsRes,
    isLoading: isTypeformFormsLoading,
    isFetched: isTypeformFormsFetched,
  } = useTypeformForms(merchant.id, connectionId);

  const {data: typeformFormRes, isFetched: isTypeformFormFetched} = useTypeformForm(
    merchant.id,
    connectionId,
    formIdField.value as string,
    {
      enabled:
        // @Devs, fetch the form only when it's a quiz.
        triggerIntegrationId.value === TypeformTakeQuizTriggerIntegrationSpec.id &&
        formIdField.value !== undefined,
    }
  );

  const typeformForms = typeformFormsRes?.output.forms ?? [];
  const sortedTypeformForms = typeformForms.sort(formA => (formA.settings.is_public ? -1 : 1));

  useEffect(() => {
    if (isTypeformFormFetched) {
      // @Devs, when the form is a quiz, check if the form has a score variable.
      if (
        triggerIntegrationId.value === TypeformTakeQuizTriggerIntegrationSpec.id &&
        typeformFormRes?.output.form.variables?.score === undefined
      ) {
        setError('triggerIntegrationConfig.formId', {
          type: 'manual',
          message: t('errorTypeformQuizWithFillFormIntegration'),
        });
      } else {
        clearErrors('triggerIntegrationConfig.formId');
      }
    }
  }, [
    triggerIntegrationId.value,
    isTypeformFormFetched,
    typeformFormRes?.output.form.variables?.score,
    setError,
    clearErrors,
    t,
  ]);

  const formsSelectionContent = match({
    typeformForms,
    isTypeformFormsLoading,
    isTypeformFormsFetched,
    formIdFieldValue: formIdField.value,
  })
    .with({isTypeformFormsLoading: true}, () => <Loader size={30} color="gray" />)
    .with({typeformForms: []}, () => <p>{t('formSelect.noFormsMessage')}</p>)
    .with({isTypeformFormsFetched: true}, () => (
      <div>
        <SelectInput
          labelPosition="top"
          label={t('formSelect.labelFormSelect')}
          description={t('formSelect.descriptionFormSelect')}
          options={sortedTypeformForms.map(
            form =>
              ({
                value: form.id,
                label: form.settings.is_public
                  ? form.title
                  : t('formSelect.privateFormLabel', {title: form.title}),
                isDisabled: !form.settings.is_public,
              }) satisfies SelectOption
          )}
          name="triggerIntegrationConfig.formId"
          register={register}
          control={control}
        />
        <div className="mt-2">
          <HighlightText
            type="info"
            text={<label>{t('formSelect.formSelectInfoMessage')}</label>}
          />
        </div>

        {triggerIntegrationId.value === TypeformTakeQuizTriggerIntegrationSpec.id && (
          <div className="mt-4 w-1/4 min-w-8">
            <Input
              label={t('formSelect.labelMinScore')}
              labelPosition="top"
              type="number"
              name="triggerIntegrationConfig.minScore"
              min={0}
              placeholder="0"
              required
              register={register}
              control={control}
            />
          </div>
        )}
      </div>
    ))
    .otherwise(() => <ErrorState />);

  return formsSelectionContent;
};

export default TypeformTriggerIntegrationConfigComponent;
