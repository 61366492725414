import SpotifyPlaylistCard from '@cohort/merchants/apps/spotify/triggers/SpotifyPlaylistCard';
import Button from '@cohort/merchants/components/buttons/Button';
import {RadioCards, RadioCardTopRightCheck} from '@cohort/merchants/components/form/RadioCards';
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetTitle,
} from '@cohort/merchants/components/modals/Sheet';
import type {StepTriggerFormType} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/challenge/utils';
import type {SpotifyPlaylist} from '@cohort/shared/apps/spotify/schema';
import {zodResolver} from '@hookform/resolvers/zod';
import type {UseFormReturn} from 'react-hook-form';
import {useController, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

const SelectPlaylistFormSchema = z.object({
  playlistId: z.string().nullable(),
});
type SelectPlaylistFormValues = z.infer<typeof SelectPlaylistFormSchema>;

type SelectPlaylistSheetProps = {
  playlists: SpotifyPlaylist[];
  formReturn: UseFormReturn<StepTriggerFormType>;
  onClose: () => void;
};
const SelectPlaylistSheet: React.FC<SelectPlaylistSheetProps> = ({
  playlists,
  formReturn,
  onClose,
}) => {
  const {setValue, control} = formReturn;
  const {field: triggerPlaylistIdField} = useController({
    name: 'triggerIntegrationConfig.playlistId',
    control,
  });
  const {t} = useTranslation('app-spotify', {
    keyPrefix: 'components.selectPlaylistSheet',
  });

  const {register: selectPlaylistRegister, control: selectPlaylistControl} =
    useForm<SelectPlaylistFormValues>({
      resolver: zodResolver(SelectPlaylistFormSchema),
      defaultValues: {
        playlistId: triggerPlaylistIdField.value ?? null,
      },
    });
  const {field: playlistIdField} = useController({
    control: selectPlaylistControl,
    name: 'playlistId',
  });

  const onSubmit = (): void => {
    if (playlistIdField.value !== null) {
      setValue('triggerIntegrationConfig.playlistId', playlistIdField.value);
    }
    onClose();
  };

  return (
    <Sheet open onOpenChange={onClose} lightMode>
      <SheetContent className="max-h-100vh !grid h-[100vh] gap-0 bg-white [grid-template-rows:min-content_1fr_min-content]">
        <SheetTitle className="p-6">{t('title')}</SheetTitle>
        <div className="m-6 mt-0 flex flex-col gap-6 overflow-y-auto rounded-2xl border border-slate-200 bg-slate-50 p-6">
          <RadioCards
            name="playlistId"
            direction="row"
            withFullHeightCards
            register={selectPlaylistRegister}
            control={selectPlaylistControl}
            className="grid grid-cols-3 gap-4"
            options={playlists.map(playlist => ({
              label: (
                <SpotifyPlaylistCard
                  playlist={playlist}
                  isSelected={playlist.id === playlistIdField.value}
                />
              ),
              value: playlist.id,
            }))}
            labelClassName="p-0"
            checkIconComponent={<RadioCardTopRightCheck />}
          />
        </div>
        <SheetFooter className="flex w-full !flex-row !justify-between border-t border-border px-6 py-4">
          <Button variant="secondary" onClick={onClose}>
            {t('buttonCancel')}
          </Button>
          <Button onClick={onSubmit}>{t('buttonConfirm')}</Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default SelectPlaylistSheet;
