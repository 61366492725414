import UrlInput from '@cohort/merchants/components/form/input/UrlInput';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {ContentSettingsStepValues} from '@cohort/merchants/pages/contents/content/formSchemas';
import {UrlInputSchema} from '@cohort/shared/schema/common/urls';
import {zodResolver} from '@hookform/resolvers/zod';
import {useEffect, useState} from 'react';
import {useForm, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {isDefined, isString} from 'remeda';
import {z} from 'zod';

const YoutubeVideoMediaSchema = z.object({
  inputUrl: UrlInputSchema,
});
type YoutubeVideoMediaValues = z.infer<typeof YoutubeVideoMediaSchema>;

const YOUTUBE_EMBED_URL = 'youtube.com/embed/';

const YOUTUBE_URLS = [
  {
    url: 'youtube.com/watch',
    extractor: (url: string) => new URLSearchParams(new URL(url).search).get('v'),
  },
  {url: 'youtu.be/', extractor: (url: string) => url.split('youtu.be/')[1]},
  {url: 'youtube.com/shorts/', extractor: (url: string) => url.split('youtube.com/shorts/')[1]},
  {url: YOUTUBE_EMBED_URL, extractor: (url: string) => url.split(YOUTUBE_EMBED_URL)[1]},
];

const buildEmbedUrl = (videoId: string): string => `https://www.${YOUTUBE_EMBED_URL}${videoId}`;

const getVideoIdFromUrl = (videoUrl: string): string | null | undefined => {
  if (!videoUrl.startsWith('http://') && !videoUrl.startsWith('https://')) {
    videoUrl = 'https://' + videoUrl;
  }

  for (const {url, extractor} of YOUTUBE_URLS) {
    if (videoUrl.includes(url)) {
      return extractor(videoUrl);
    }
  }
  return undefined;
};

const isDefinedVideoId = (videoId: unknown): videoId is string =>
  isDefined(videoId) && isString(videoId);

const YoutubeVideoMediaConfigComponent: React.FC = () => {
  const [isUrlInvalid, setIsUrlInvalid] = useState(false);
  const {watch, setValue} = useCohortForm<ContentSettingsStepValues>();
  const {t} = useTranslation('app-youtube', {
    keyPrefix: 'medias.video.configComponent',
  });
  const videoId = watch('media.config.videoId');

  // Form for the input URL.
  const {register: registerInputUrl, control: controlInputUrl} = useForm<YoutubeVideoMediaValues>({
    defaultValues: {
      inputUrl: isDefinedVideoId(videoId) ? buildEmbedUrl(videoId) : null,
    },
    resolver: zodResolver(YoutubeVideoMediaSchema),
  });

  const inputUrl = useWatch({
    control: controlInputUrl,
    name: 'inputUrl',
  });

  useEffect(() => {
    if (inputUrl === null) {
      return;
    }

    const videoId = getVideoIdFromUrl(inputUrl);
    if (videoId === undefined && inputUrl.length > 0) {
      setIsUrlInvalid(true);
    } else {
      setIsUrlInvalid(false);
    }

    setValue('media.config.videoId', videoId);
  }, [inputUrl, setValue]);

  return (
    <div className="space-y-4">
      <div>
        <UrlInput
          name="inputUrl"
          register={registerInputUrl}
          control={controlInputUrl}
          placeholder={t('urlInputPlaceholder')}
        />
        {isUrlInvalid && <p className="mt-1 text-sm text-red-600">{t('invalidUrl')}</p>}
      </div>
      {isDefinedVideoId(videoId) && (
        <iframe
          className="w-full rounded-xl"
          height="450"
          src={buildEmbedUrl(videoId)}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      )}
    </div>
  );
};

export default YoutubeVideoMediaConfigComponent;
