import {useUserEventApps} from '@cohort/merchants/apps/useUserEventApps';
import {SelectPicker} from '@cohort/merchants/components/form/select/SelectPicker';
import type {FormField} from '@cohort/merchants/lib/form/utils';
import AppLogo from '@cohort/merchants/pages/users/overview/appLogo';
import type {EventId} from '@cohort/shared/apps';
import {EventIdSchema} from '@cohort/shared/apps';
import type {ComponentProps} from 'react';
import type {FieldValues} from 'react-hook-form';
import {useController} from 'react-hook-form';

type EventTypeLabelProps = {
  eventId: EventId;
};

const EventTypeLabel: React.FC<EventTypeLabelProps> = ({eventId}) => {
  const {getUserEventTitle, getUserEventApp} = useUserEventApps();

  const app = getUserEventApp(eventId);

  return (
    <div className="flex justify-between">
      <div className="mr-1">{getUserEventTitle(eventId)}</div>
      <AppLogo app={app} tooltipContent={app.spec.name} />
    </div>
  );
};

type UserEventsPickerProps<T extends FieldValues> = ComponentProps<typeof SelectPicker> &
  FormField<T>;

// eslint-disable-next-line @typescript-eslint/naming-convention
function UserEventsPicker<T extends FieldValues>({
  control,
  name,
  label,
}: UserEventsPickerProps<T>): JSX.Element {
  const {getUserEventTitle} = useUserEventApps();
  const {field, fieldState} = useController({
    control: control,
    name: name,
  });

  const options = EventIdSchema.options.map(option => ({
    label: getUserEventTitle(option),
    value: option,
  }));

  const value = options.filter(option =>
    field.value ? field.value.includes(option.value) : false
  );

  return (
    <SelectPicker
      isMulti
      options={options}
      value={value}
      formatOptionLabel={data => <EventTypeLabel eventId={data.value} />}
      onChange={data => field.onChange(data.map(option => option.value))}
      name={name}
      label={label}
      error={fieldState.error?.message}
      autoFocus
    />
  );
}

export default UserEventsPicker;
