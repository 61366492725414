import type {TriggerIntegration} from '@cohort/merchants/apps';
import TikTokKeywordInDescriptionTriggerIntegrationConfigComponent from '@cohort/merchants/apps/tiktok/triggers/keywordInDescription/ConfigComponent';
import TikTokKeywordInDescriptionTriggerIntegrationStepCompletionContextComponent from '@cohort/merchants/apps/tiktok/triggers/keywordInDescription/StepCompletionContextComponent';
import TikTokKeywordInDescriptionTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/tiktok/triggers/keywordInDescription/StepCompletionTitleComponent';
import type {TikTokKeywordInDescriptionTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/keywordInDescription';
import {TikTokKeywordInDescriptionTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/keywordInDescription';

export const TikTokKeywordInDescriptionTriggerIntegration: TriggerIntegration<TikTokKeywordInDescriptionTriggerStruct> =
  {
    spec: TikTokKeywordInDescriptionTriggerSpec,
    configComponent: TikTokKeywordInDescriptionTriggerIntegrationConfigComponent,
    stepCompletionTitleComponent:
      TikTokKeywordInDescriptionTriggerIntegrationStepCompletionTitleComponent,
    stepCompletionContextComponent:
      TikTokKeywordInDescriptionTriggerIntegrationStepCompletionContextComponent,
    getTitle: t => t('triggers.keyword-in-description.title', {ns: 'app-tiktok'}),
  };
