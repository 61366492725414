import type {CreateConnectionDataADto} from '@cohort/admin-schemas/connection';
import type {ConnectionEditComponentProps} from '@cohort/merchants/apps';
import Button from '@cohort/merchants/components/buttons/Button';
import ConnectionHeader from '@cohort/merchants/components/connections/ConnectionHeader';
import Input from '@cohort/merchants/components/form/input/Input';
import {RadioCards} from '@cohort/merchants/components/form/RadioCards';
import Textarea from '@cohort/merchants/components/form/textarea/Textarea';
import {connectionsKeys} from '@cohort/merchants/hooks/api/Connections';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {createConnection} from '@cohort/merchants/lib/api/Connections';
import {notifyError} from '@cohort/merchants/stores/ErrorModalStore';
import type {PostgresqlCredentials} from '@cohort/shared/apps/postgresql';
import {PostgresqlCredentialsSchema} from '@cohort/shared/apps/postgresql';
import {REDACTED_FIELD} from '@cohort/shared/constants';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryClient} from '@tanstack/react-query';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

const PostgresqlEditConnectionComponent: React.FC<ConnectionEditComponentProps> = ({
  existingConnection: connectionToEdit,
  onClose,
  showCancelBtn,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const queryClient = useQueryClient();
  const {t} = useTranslation('app-postgresql', {
    keyPrefix: 'editConnectionComponent',
  });

  const {register, handleSubmit, control, watch} = useForm<
    PostgresqlCredentials & {sslInputs: 'visible' | 'hidden'}
  >({
    resolver: zodResolver(PostgresqlCredentialsSchema),
    defaultValues: {
      url: connectionToEdit ? REDACTED_FIELD : undefined,
      schema: connectionToEdit ? REDACTED_FIELD : undefined,
      sslInputs: 'hidden',
      ssl: {
        rejectUnauthorized: false,
        ca: undefined,
        cert: undefined,
        key: undefined,
      },
    },
  });

  const [sslInputs] = watch(['sslInputs']);

  const {isLoading, mutate: createOrFixConnectionMutation} = useCohortMutation({
    mutationFn: async (data: CreateConnectionDataADto) => createConnection(merchantId, data),
    notifySuccessMessage: t('createSuccessNotification'),
    onSuccess: async connection => {
      await queryClient.invalidateQueries(connectionsKeys.list(merchantId));
      onClose?.(connection);
    },
    onError: err => notifyError(err, t('errorInvalidCredentials')),
  });

  return (
    <form
      onSubmit={e => {
        e.stopPropagation();
        handleSubmit(async data => {
          createOrFixConnectionMutation({
            connectorId: 'postgresql',
            name: null,
            credentials: {
              url: data.url,
              schema: data.schema,
              // Returns null if the merchant didn't opt-in for SSL, else we return an empty config or the form values if set.
              ssl: sslInputs === 'visible' ? data.ssl ?? {} : null,
            },
            existingConnectionId: connectionToEdit?.id,
          });
        })(e);
      }}
      className="space-y-6 p-6"
      data-testid="create-connection-form"
      id="create-connection-form"
    >
      <ConnectionHeader appId="postgresql" title={t('title')} />
      <Input type="text" name="url" label={t('labelUrl')} register={register} control={control} />
      <Input
        type="text"
        name="schema"
        label={t('labelSchema')}
        register={register}
        control={control}
      />
      <RadioCards
        name="sslInputs"
        direction="row"
        label={t('labelSslRadioCards')}
        register={register}
        control={control}
        options={[
          {
            label: t('labelNoSsl'),
            value: 'hidden',
          },
          {
            label: t('labelUseSsl'),
            value: 'visible',
          },
        ]}
      />
      {sslInputs === 'visible' && (
        <div className="space-y-6">
          <Input
            type="checkbox"
            name="ssl.rejectUnauthorized"
            label={t('labelSslRejectUnauthorized')}
            register={register}
            control={control}
            labelPosition="right"
          />
          <Textarea
            name="ssl.ca"
            label={t('labelSslCa')}
            rows={5}
            register={register}
            control={control}
          />
          <Textarea
            name="ssl.cert"
            label={t('labelSslCert')}
            rows={5}
            register={register}
            control={control}
          />
          <Textarea
            name="ssl.key"
            label={t('labelSslKey')}
            rows={5}
            register={register}
            control={control}
          />
        </div>
      )}

      <div className="flex justify-between">
        <Button
          data-testid="create-digital-asset-collection-submit-btn"
          type="submit"
          form="create-connection-form"
          loading={isLoading}
        >
          {connectionToEdit ? t('buttonReconnect') : t('buttonCreate')}
        </Button>
        {showCancelBtn && (
          <Button
            type="button"
            variant="secondary"
            className="flex gap-2"
            onClick={() => onClose?.(null)}
          >
            {t('buttonCancel')}
          </Button>
        )}
      </div>
    </form>
  );
};

export default PostgresqlEditConnectionComponent;
