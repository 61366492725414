import type {UserEventADto} from '@cohort/admin-schemas/userEvents';
import type {CohortFormQuestionType} from '@cohort/shared/schema/common/cohortForm';
import {formatDate} from '@cohort/shared/utils/format';
import {cn} from '@cohort/shared-frontend/utils/classNames';

export const formatCohortFormResponse = (
  questionType: CohortFormQuestionType,
  response: string | number | boolean | Date | null | undefined
): string => {
  if (response === undefined || response === null || response === '') {
    return '-';
  }
  if (questionType === 'date') {
    return formatDate(new Date(response.toString()));
  }
  return response.toString();
};

export const formatDetailComponentBoldText = (
  userEvent: UserEventADto,
  navigateFn?: () => void
): JSX.Element => {
  return (
    <span
      className={cn('font-semibold', !userEvent.isResourceDeleted && 'cursor-pointer text-primary')}
      onClick={() => !userEvent.isResourceDeleted && navigateFn?.()}
    />
  );
};
