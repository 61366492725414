import type {SyncConfigComponentProps} from '@cohort/merchants/apps';
import ExportUsersConfig from '@cohort/merchants/apps/shopify/sync/ExportUsersConfig';
import SwitchInput from '@cohort/merchants/components/form/SwitchInput';
import type {SyncConfigFormValues} from '@cohort/merchants/pages/apps/app/utils';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

const ShopifySyncConfigComponent: React.FC<SyncConfigComponentProps> = ({connection}) => {
  const {t} = useTranslation('app-shopify', {
    keyPrefix: 'sync.syncConfigComponent',
  });

  const {register, control, setValue, watch} =
    useFormContext<Extract<SyncConfigFormValues, {appId: 'shopify'}>>();

  const isImportEnabled = watch('userImportEnabled');

  return (
    <div className="mb-24 space-y-6">
      <div className="flex flex-col gap-6 rounded-lg border border-slate-200 p-6">
        <div className="flex flex-col gap-2">
          <p className="text-lg font-medium">{t('titleUserImport')}</p>
          <p className="text-sm text-slate-500">{t('descriptionUserImport')}</p>
          <SwitchInput
            className="mt-4"
            name="userImportEnabled"
            label={t('labelEnableUserImport')}
            labelPosition="right"
            register={register}
            control={control}
            onCheckedChange={check => {
              setValue('userImportEnabled', check);
              if (!check) {
                setValue('userExportEnabled', check);
              }
            }}
          />
        </div>
      </div>
      <div className="flex flex-col gap-6 rounded-lg border border-slate-200 p-6">
        <div className="flex flex-col gap-2">
          <p className="text-lg font-medium">{t('titleUserExport')}</p>
          <p className="text-sm text-slate-500">{t('descriptionUserExport')}</p>
          <SwitchInput
            className="mt-4"
            name="userExportEnabled"
            label={t('labelEnableUserExport')}
            labelPosition="right"
            register={register}
            control={control}
            disabled={!isImportEnabled}
          />
        </div>
        <ExportUsersConfig connection={connection} />
      </div>
    </div>
  );
};

export default ShopifySyncConfigComponent;
