import type {TikTokKeywordInBioVerificationAttemptData} from '@cohort/shared/apps/tiktok/triggers/keywordInBio';
import {Fragment} from 'react';

const TikTokKeywordInBioTriggerIntegrationStepCompletionContextComponent: React.FC<{
  data: TikTokKeywordInBioVerificationAttemptData;
}> = ({data}) => {
  const {bioDescription} = data;

  if (bioDescription === undefined) {
    return <Fragment />;
  }

  return (
    <div className="rounded-lg border border-slate-200 bg-slate-50 p-4">
      <p className="text-sm text-slate-700">{bioDescription}</p>
    </div>
  );
};

export default TikTokKeywordInBioTriggerIntegrationStepCompletionContextComponent;
