import type {TriggerIntegration} from '@cohort/merchants/apps';
import TikTokLikeTiktokTriggerIntegrationConfigComponent from '@cohort/merchants/apps/tiktok/triggers/likeTiktok/ConfigComponent';
import TikTokLikeTiktokTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/tiktok/triggers/likeTiktok/StepCompletionTitleComponent';
import type {TikTokLikeTiktokTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/likeTiktok';
import {TikTokLikeTiktokTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/likeTiktok';

export const TikTokLikeTiktokTriggerIntegration: TriggerIntegration<TikTokLikeTiktokTriggerStruct> =
  {
    spec: TikTokLikeTiktokTriggerSpec,
    configComponent: TikTokLikeTiktokTriggerIntegrationConfigComponent,
    stepCompletionTitleComponent: TikTokLikeTiktokTriggerIntegrationStepCompletionTitleComponent,
    getTitle: t => t('triggers.like-tiktok.title', {ns: 'app-tiktok'}),
  };
