import {TIKTOK_URL} from '@cohort/shared/apps/tiktok/common';
import type {TikTokLikeTiktokVerificationAttemptData} from '@cohort/shared/apps/tiktok/triggers/likeTiktok';
import {Trans} from 'react-i18next';

const TikTokLikeTiktokTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: TikTokLikeTiktokVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data}) => {
  const linkClassName = 'font-semibold text-gray-900';
  const linkRel = 'noopener noreferrer nofollow';

  const userLink = `${TIKTOK_URL}/@${data.username}`;

  return (
    <Trans
      i18nKey="triggers.like-tiktok.stepCompletionTitleComponent.success"
      ns="app-tiktok"
      values={{username: data.username}}
      components={{
        userLink: <a className={linkClassName} target="_blank" rel={linkRel} href={userLink} />,
        tiktokLink: <a className={linkClassName} target="_blank" rel={linkRel} href={data.url} />,
      }}
    />
  );
};

export default TikTokLikeTiktokTriggerIntegrationStepCompletionTitleComponent;
