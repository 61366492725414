import TikTokVideoCard from '@cohort/merchants/apps/tiktok/triggers/components/TikTokVideoCard';
import Button from '@cohort/merchants/components/buttons/Button';
import EmptyState from '@cohort/merchants/components/EmptyState';
import {RadioCards, RadioCardTopRightCheck} from '@cohort/merchants/components/form/RadioCards';
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetTitle,
} from '@cohort/merchants/components/modals/Sheet';
import type {StepTriggerFormType} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/challenge/utils';
import type {TikTokVideo} from '@cohort/shared/apps/tiktok/common';
import {zodResolver} from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import type {UseFormReturn} from 'react-hook-form';
import {useController, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';
import {z} from 'zod';

const SelectVideoFormSchema = z.object({
  videoId: z.string().nullable(),
});
type SelectVideoFormValues = z.infer<typeof SelectVideoFormSchema>;

type SelectVideoSheetProps = {
  videos: TikTokVideo[];
  formReturn: UseFormReturn<StepTriggerFormType>;
  onClose: () => void;
};
const SelectVideoSheet: React.FC<SelectVideoSheetProps> = ({videos, formReturn, onClose}) => {
  const {setValue, control} = formReturn;
  const {field: triggerVideoIdField} = useController({
    name: 'triggerIntegrationConfig.videoId',
    control,
  });
  const {t} = useTranslation('app-tiktok', {
    keyPrefix: 'triggers.components.selectVideoSheet',
  });

  const {register: selectVideoRegister, control: selectVideoControl} =
    useForm<SelectVideoFormValues>({
      resolver: zodResolver(SelectVideoFormSchema),
      defaultValues: {
        videoId: triggerVideoIdField.value ?? null,
      },
    });
  const {field: videoIdField} = useController({control: selectVideoControl, name: 'videoId'});

  const onSubmit = (): void => {
    if (videoIdField.value !== null) {
      setValue('triggerIntegrationConfig.videoId', videoIdField.value);
      setValue(
        'triggerIntegrationConfig.url',
        videos.find(video => video.id === videoIdField.value)?.shareUrl
      );
    }
    onClose();
  };

  const videosByMonth = videos.reduce((groups, video) => {
    const monthYear = dayjs(video.createdAt).format('MMMM YYYY');
    if (!groups.has(monthYear)) {
      groups.set(monthYear, []);
    }
    groups.get(monthYear)?.push(video);
    return groups;
  }, new Map<string, TikTokVideo[]>());

  const videosContent = match(videosByMonth.size)
    .with(0, () => (
      <div className="flex h-full flex-col justify-center">
        <EmptyState title={<span className="text-sm font-medium">{t('titleEmpty')}</span>} />
      </div>
    ))
    .otherwise(() =>
      Array.from(videosByMonth).map(([monthYear, videos]) => (
        <div key={monthYear} className="flex flex-col space-y-3">
          <p className="text-sm text-slate-700">{monthYear}</p>
          <RadioCards
            name="videoId"
            direction="row"
            register={selectVideoRegister}
            control={selectVideoControl}
            className="grid grid-cols-1"
            options={videos.map(video => ({
              label: <TikTokVideoCard video={video} isSelected={video.id === videoIdField.value} />,
              value: video.id,
            }))}
            labelClassName="p-0"
            checkIconComponent={<RadioCardTopRightCheck />}
          />
        </div>
      ))
    );

  return (
    <Sheet open onOpenChange={onClose}>
      <SheetContent className="max-h-100vh flex flex-col gap-0 bg-white pt-6">
        <SheetTitle className="flex-shrink-0 px-6">{t('title')}</SheetTitle>
        <div className="no-scrollbar m-6 flex flex-grow flex-col gap-4 overflow-y-auto rounded-lg border border-slate-200 bg-slate-50 p-4">
          {videosContent}
        </div>
        <SheetFooter className="flex w-full !flex-row !justify-between border-t border-border px-6 py-4">
          <Button variant="secondary" onClick={onClose}>
            {t('buttonCancel')}
          </Button>
          <Button onClick={onSubmit} disabled={videoIdField.value === null}>
            {t('buttonConfirm')}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default SelectVideoSheet;
