import type {TriggerIntegration} from '@cohort/merchants/apps';
import TikTokKeywordInBioTriggerIntegrationConfigComponent from '@cohort/merchants/apps/tiktok/triggers/keywordInBio/ConfigComponent';
import TikTokKeywordInBioTriggerIntegrationStepCompletionContextComponent from '@cohort/merchants/apps/tiktok/triggers/keywordInBio/StepCompletionContextComponent';
import TikTokKeywordInBioTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/tiktok/triggers/keywordInBio/StepCompletionTitleComponent';
import type {TikTokKeywordInBioTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/keywordInBio';
import {TikTokKeywordInBioTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/keywordInBio';

export const TikTokKeywordInBioTriggerIntegration: TriggerIntegration<TikTokKeywordInBioTriggerStruct> =
  {
    spec: TikTokKeywordInBioTriggerSpec,
    configComponent: TikTokKeywordInBioTriggerIntegrationConfigComponent,
    stepCompletionTitleComponent: TikTokKeywordInBioTriggerIntegrationStepCompletionTitleComponent,
    stepCompletionContextComponent:
      TikTokKeywordInBioTriggerIntegrationStepCompletionContextComponent,
    getTitle: t => t('triggers.keyword-in-bio.title', {ns: 'app-tiktok'}),
  };
