import type {SyncConfigComponentProps} from '@cohort/merchants/apps';
import {RadioCards} from '@cohort/merchants/components/form/RadioCards';
import SwitchInput from '@cohort/merchants/components/form/SwitchInput';
import type {SyncConfigFormValues} from '@cohort/merchants/pages/apps/app/utils';
import {useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type ExportUsersConfigProps = SyncConfigComponentProps;
const ExportUsersConfig: React.FC<ExportUsersConfigProps> = () => {
  const {setValue, register, control, watch} =
    useFormContext<Extract<SyncConfigFormValues, {appId: 'shopify'}>>();

  const {t} = useTranslation('app-shopify', {
    keyPrefix: 'sync.exportUsersConfig',
  });

  const createCustomerSegments = watch('userExportConfig.createCustomerSegments');

  useEffect(() => {
    if (createCustomerSegments !== true) {
      setValue('userExportConfig.createCustomerSegments', false);
    }
  }, [createCustomerSegments, setValue]);

  const isExportEnabled = watch('userExportEnabled');

  return (
    <div className="flex flex-col space-y-4">
      <RadioCards
        name="userExportConfig.createCustomers"
        label={t('labelUserGroup')}
        register={register}
        control={control}
        disabled={!isExportEnabled}
        options={[
          {label: t('labelAllUsers'), value: 'true'},
          {label: t('labelExistingShopifyUsers'), value: 'false'},
        ]}
        onChange={e => {
          setValue('userExportConfig.createCustomers', e.target.value === 'true');
        }}
        preventFieldOnChange
      />
      <div className="grid items-center gap-4 [grid-template-columns:max-content_1fr]">
        <SwitchInput
          name="userExportConfig.createCustomerSegments"
          labelPosition="right"
          register={register}
          control={control}
          disabled={!isExportEnabled}
        />
        <div>
          <p className="text-sm font-medium text-slate-700">{t('labelCreateCustomerSegments')}</p>
          <p className="text-sm text-slate-500">{t('descriptionCreateCustomerSegments')}</p>
        </div>
      </div>
    </div>
  );
};

export default ExportUsersConfig;
