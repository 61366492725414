import UrlInput from '@cohort/merchants/components/form/input/UrlInput';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {ContentSettingsStepValues} from '@cohort/merchants/pages/contents/content/formSchemas';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {isDefined} from 'remeda';

const CohortLinkMediaConfigComponent: React.FC = () => {
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'medias.link.configComponent',
  });
  const {control, watch, setValue, register} = useCohortForm<ContentSettingsStepValues>();

  useEffect(() => {
    const url = watch('media.config.url');
    const updatedUrl = isDefined(url) ? String(url).replace('https://', '') : null;
    setValue('media.config.url', updatedUrl);
  }, [setValue, watch]);

  return (
    <UrlInput
      name="media.config.url"
      register={register}
      control={control}
      placeholder={t('urlInputPlaceholder')}
      type="text"
    />
  );
};

export default CohortLinkMediaConfigComponent;
