import type {PerkIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {assertConnectionId} from '@cohort/merchants/apps';
import {useTalonOneCampaigns} from '@cohort/merchants/apps/talon-one/api';
import ErrorState from '@cohort/merchants/components/ErrorState';
import Input from '@cohort/merchants/components/form/input/Input';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import type {SelectOption} from '@cohort/merchants/components/form/select/SelectPicker';
import Loader from '@cohort/merchants/components/Loader';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {PerkFormValues} from '@cohort/merchants/pages/perks/perk/edit/utils';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const TalonOneCouponPerkIntegrationConfigComponent: React.FC<
  PerkIntegrationConfigComponentProps
> = ({connectionId}) => {
  assertConnectionId(connectionId);

  const merchant = useCurrentMerchant();
  const {t} = useTranslation('app-talon-one', {
    keyPrefix: 'perkIntegrations.coupon.configComponent',
  });
  const {control, register} = useCohortForm<PerkFormValues>();

  const {
    data: campaignsResponse,
    isLoading: isLoading,
    isFetched: isFetched,
  } = useTalonOneCampaigns(merchant.id, connectionId);

  const campaigns = campaignsResponse?.output.campaigns ?? [];

  const formsSelectionContent = match({
    campaigns,
    isLoading,
    isFetched,
  })
    .with({isLoading: true}, () => <Loader size={30} color="gray" />)
    .with({campaigns: []}, () => <p>{t('noCampaignsMessage')}</p>)
    .with({isFetched: true}, () => (
      <div>
        <SelectInput
          labelPosition="top"
          label={t('labelCampaignSelect')}
          options={campaigns.map(
            campaign =>
              ({
                value: campaign.id,
                label: campaign.name,
              }) satisfies SelectOption
          )}
          name="integration.config.campaignId"
          register={register}
          control={control}
        />

        <Input
          type="number"
          name="integration.config.usageLimit"
          label={t('labelUsageLimit')}
          register={register}
          control={control}
        />

        <Input
          type="url"
          name="integration.config.storeUrl"
          label={t('labelStoreUrl')}
          register={register}
          control={control}
        />
      </div>
    ))
    .otherwise(() => <ErrorState />);

  return formsSelectionContent;
};

export default TalonOneCouponPerkIntegrationConfigComponent;
